import React, { useEffect, useRef } from 'react';
import { setConfig } from 'react-hot-loader';
import { FrontPage } from '../components/index/front-page';
import { Testimonies } from '../components/index/testimonies';
import { Navbar } from '../components/navbar/navbar';
import { PageWrapper } from '../components/index/helpers';
import { CaseStudy } from '../components/index/case-study';
import { Awards } from '../components/index/awards';
import { Seo } from '../components/seo';

import { Parallax, ParallaxLayer } from 'react-spring/addons';

import moshi from '../../static/images/studies/moshi.png';
import pixelpin from '../../static/images/studies/pixelpin.png';
import nkbm from '../../static/images/case-studies/mbn.png';
import { LetsGetStarted } from '../components/index/lets-get-started';

import './fix-intertia.css';
import { useScrollingPage } from '../components/shared/scrolling-page.hook';
import { LastPage } from '../components/index/last-page';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/layout';

setConfig({ pureSFC: true });

export const MAX_PAGES = 8;

const Index = () => {
    const { page, setPage, canScroll, setCanScroll } = useScrollingPage(MAX_PAGES);
    const parallax = useRef();

    // Page switcher
    useEffect(() => {
        if (parallax.current) {
            parallax.current.scrollTo(page);
        }
    }, [page]);

    // Function for changing the bg color depending on the page we are currently at
    const getBgColor = () => {
        if (page < 2 || page > 4) {
            return null;
        } else {
            return ['#1880c8', '#28265a', '#7a102a'][page - 2];
        }
    };

    useEffect(() => {
        parallax.current.updateRaf();
    }, []);

    let hideLogo = false;
    if (typeof window !== 'undefined') {
        if (window.innerWidth < 765) {
            hideLogo = true;
        }

        // phone in landscape
        if (window.innerWidth > window.innerHeight && window.innerWidth < 825) {
            hideLogo = true;
        }
    }

    return (
        <Layout withNavigation={false} setCanScroll={setCanScroll} canScroll={canScroll} hideLogo={hideLogo}>
            <PageWrapper bg={getBgColor()}>
                <Helmet>
                    <body className={'fix-inertia'} />
                </Helmet>
                <Seo />
                <Navbar
                    theme={page !== 1 && page !== 5 ? null : 'dark'}
                    showShadow={page === 0}
                    backToTop={() => setPage(0)}
                    setCanScroll={setCanScroll}
                    canScroll={canScroll}
                />
                <Parallax ref={parallax} pages={MAX_PAGES} scrolling={false} config={{ duration: 200 }}>
                    <ParallaxLayer offset={0} speed={0}>
                        <FrontPage />
                    </ParallaxLayer>
                    <ParallaxLayer offset={1} speed={0}>
                        <Testimonies />
                    </ParallaxLayer>
                    <ParallaxLayer offset={2} speed={0}>
                        <CaseStudy
                            title={'Moshi twilight.'}
                            subTitle={'Mobile app development.'}
                            image={moshi}
                            color={'#197fc7'}
                            to={'case-study/moshi'}
                        />
                    </ParallaxLayer>
                    <ParallaxLayer offset={3} speed={0}>
                        <CaseStudy
                            title={'PixelPin'}
                            subTitle={'Mobile app development.'}
                            image={pixelpin}
                            color={'#407885'}
                            to={'case-study'}
                        />
                    </ParallaxLayer>
                    <ParallaxLayer offset={4} speed={0}>
                        <CaseStudy
                            title={'NKBM'}
                            subTitle={'Mobile app development.'}
                            image={nkbm}
                            color={'#7a102a'}
                            design={'web'}
                            to={'case-study'}
                        />
                    </ParallaxLayer>
                    <ParallaxLayer offset={5} speed={0}>
                        <Awards />
                    </ParallaxLayer>
                    <ParallaxLayer offset={6} speed={0}>
                        <LetsGetStarted />
                    </ParallaxLayer>
                    <ParallaxLayer offset={7} speed={0}>
                        <LastPage hideLogo={hideLogo} />
                    </ParallaxLayer>
                </Parallax>
            </PageWrapper>
        </Layout>
    );
};

export default Index;
