import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Container } from 'reactstrap';
import { PRIMARY_BG_COLOR, TITLE_FONT } from '../constants';

const HeaderWrapper = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const BackgroundVideoContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${PRIMARY_BG_COLOR};

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media (min-aspect-ratio: 16/9) {
            height: 300%;
            top: -100%;
        }

        @media (max-aspect-ratio: 16/9) {
            width: 400%;
            left: -150%;
        }
    }

    .mobile {
        min-height: 170%;
    }
`;

const Title = styled.h1`
    font-size: 188px;
    font-weight: bold;
    font-family: ${TITLE_FONT};
    margin-top: 188px;
    text-indent: -14px;
`;

const SubTitle = styled.div`
    font-weight: normal;
    font-size: 36px;
`;

export const Heading = props => {
    const style = {};

    if (props.color) {
        style.backgroundColor = props.color;
    }

    return (
        <HeaderWrapper style={style}>
            <BackgroundVideoContainer>
                <video
                    src={props.video[1].source}
                    autoPlay="autoplay"
                    loop="loop"
                    muted="muted"
                    playsInline
                    preload="true"
                    className="fullscreen-bg__video d-none d-md-block"
                />
                <video
                    src={props.mobileVideo.source}
                    autoPlay="autoplay"
                    loop="loop"
                    muted="muted"
                    playsInline
                    preload="true"
                    className="fullscreen-bg__video mobile d-block d-md-none"
                />
            </BackgroundVideoContainer>
            <Container>
                {props.title ? <Title>{props.title}</Title> : null}
                {props.subTitle ? <SubTitle>{props.subTitle}</SubTitle> : null}
                {props.children}
            </Container>
        </HeaderWrapper>
    );
};

Heading.propTypes = {
    children: PropTypes.node.isRequired,
    cover: PropTypes.string,
    video: PropTypes.array,
    color: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
};
