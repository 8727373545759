import styled from 'styled-components';
import React from 'react';

import { Col, Container, Row } from 'reactstrap';
import { PRIMARY_TEXT_COLOR, TITLE_FONT } from '../../constants';

const CaseStudyTitle = styled.h2`
    width: 298px;
    font-family: ${TITLE_FONT};
    font-size: 44px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    max-width: 100%;
    color: ${PRIMARY_TEXT_COLOR};

    @media (max-width: 768px) {
        font-size: 28px;
    }

    @media (max-height: 700px) {
        font-size: 32px;
    }
`;

const CaseStudySubTitle = styled.h5`
    font-family: ${TITLE_FONT};
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.2px;
    color: ${PRIMARY_TEXT_COLOR};

    @media (max-width: 768px) {
        font-size: 18px;
        width: 130px;
    }
`;

const CaseImage = styled.img`
    display: block;
    height: 90vh;
    width: auto;

    @media (max-width: 768px) {
        display: block;
        height: 70vh;
        width: auto;
    }
`;

const About = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

const CaseStudyContainer = styled(Container)`
    .parent {
        position: relative;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const CaseStudy = ({ image, title, subTitle, video, to, color, design }) => {
    return (
        <>
            <CaseStudyContainer className="d-flex flex-column justify-content-center h-100">
                <Row>
                    <Col xs={5} md={6}>
                        <About>
                            <CaseStudyTitle>{title}</CaseStudyTitle>
                            <CaseStudySubTitle>{subTitle}</CaseStudySubTitle>
                        </About>
                    </Col>
                    <Col xs={7} md={6} className="parent">
                        <CaseImage src={image} design={design} />
                    </Col>
                </Row>
            </CaseStudyContainer>
        </>
    );
};
