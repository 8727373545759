import React from 'react';
import styled from 'styled-components';

import { PRIMARY_TEXT_COLOR, PRIMARY_TITLE_COLOR, TESTIMONIES, TITLE_FONT } from '../../constants';
import { Col, Container, Row } from 'reactstrap';
import { Testimony } from '../testimony/testimony';
import { SeeMore } from '../shared';

const Customers = styled.div`
    background-color: ${PRIMARY_TEXT_COLOR};
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;

    .container {
        .what-customers-say {
            margin-bottom: 3rem;
            @media (max-height: 568px) {
                margin-bottom: 0rem;
                margin-top: 4rem;
            }
        }
    }
`;

const WhatCustomers = styled.a`
    font-size: 32px;
    letter-spacing: -0.4px;
    font-family: ${TITLE_FONT};
    font-weight: bold;
    color: ${PRIMARY_TITLE_COLOR};
    margin-bottom: 80px;
    margin-top: 80px;

    @media (max-height: 700px) {
        margin-bottom: 50px;
        font-size: 32px;

        br {
            display: none;
        }
    }

    @media (max-width: 768px) {
        font-size: 28px;
        letter-spacing: -0.2px;
        padding-top: 40px;
        margin-bottom: 40px;

        br {
            display: block;
        }
    }
`;

const HeightConservingDiv = styled.div`
    display: none;
    margin-top: 20px;
    @media screen and (min-height: 679px) {
        display: block;
    }
`;

export const Testimonies = () => {
    return (
        <Customers key="testimonies">
            <Container>
                <Row className={'what-customers-say'}>
                    <Col>
                        <WhatCustomers>What our customers say?</WhatCustomers>
                    </Col>
                </Row>
                <Row className={'mb-5'}>
                    {TESTIMONIES.map((item, index) => {
                        return (
                            <Col lg={4} xs={12} className={index >= 1 ? 'd-none d-lg-block' : ''} key={'testimony-' + index}>
                                <Testimony
                                    position={item.title}
                                    name={item.name}
                                    source={item.source}
                                    image={item.image}
                                    quote={item.text}
                                    sourceHref={item.sourceHref}
                                />
                            </Col>
                        );
                    })}
                </Row>
                <Row className="mt-5 mt-md-0">
                    <Col>
                        <HeightConservingDiv>
                            <SeeMore to="https://clutch.co/profile/equaleyes-solutions" />
                        </HeightConservingDiv>
                    </Col>
                </Row>
            </Container>
        </Customers>
    );
};
