import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PRIMARY_TITLE_COLOR, SECONDARY_TITLE_COLOR, TEXT_FONT } from '../../constants';

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const Quote = styled.div`
    flex-grow: 1;
    font-family: ${TEXT_FONT};
    font-size: 22px;
    color: ${PRIMARY_TITLE_COLOR};
    letter-spacing: -0.2px;
    line-height: 30px;
    padding-right: 35px;

    @media (max-width: 768px) {
        font-size: 18px;
        letter-spacing: -0.1px;
    }

    @media (max-height: 700px) {
        font-size: 18px;
        letter-spacing: -0.1px;
    }
`;

const Meta = styled.div`
    flex-grow: 0;
    margin-bottom: 0px;
`;

const Title = styled.div`
    margin-top: 30px;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    font-family: ${TEXT_FONT};
    letter-spacing: -0.2px;

    @media (max-height: 700px) {
        font-size: 15px;
        letter-spacing: -0.1px;
    }
`;

const Name = styled.div`
    margin-bottom: 30px;
    font-size: 15px;
    font-family: ${TEXT_FONT};
    line-height: 22px;
    letter-spacing: -0.2px;

    @media (max-height: 700px) {
        font-size: 15px;
        letter-spacing: -0.1px;
    }
`;

const ImageContainer = styled.div`
    height: 45px;
    img {
        height: 40px;
    }
`;

const Image = styled.img``;

const Source = styled.a`
    font-family: ${TEXT_FONT};
    color: ${SECONDARY_TITLE_COLOR};
    letter-spacing: -0.2px;
    font-size: 18px;
`;

export const Testimony = ({ quote, position, name, image, imageAlt, source, sourceHref }) => {
    return (
        <Container>
            <Quote>{quote}</Quote>
            {source !== null && <Source href={sourceHref}>{source}</Source>}
            <Meta>
                <Title>{position}</Title>
                <Name>{name}</Name>
                <ImageContainer>
                    <Image src={image} alt={imageAlt} />
                </ImageContainer>
            </Meta>
        </Container>
    );
};

Testimony.propTypes = {
    quote: PropTypes.string,
    position: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    imageAlt: PropTypes.string,
    source: PropTypes.string,
    sourceHref: PropTypes.string,
};

Testimony.defaultProps = {
    source: 'Source: Clutch.co',
};
