import React from 'react';
import styled from 'styled-components';

import { CallToActionButton } from '../shared';
import { Fullscreen } from '../fullscreen/fullscreen';
import { Heading } from '../heading';
import { Link } from 'gatsby';
import { Col, Row } from 'reactstrap';
import { PRIMARY_TEXT_COLOR, PRIMARY_TITLE_COLOR, TITLE_FONT } from '../../constants';

import cover from '../../../static/images/cover.jpg';
import coverVideoMp4 from '../../../static/videos/cover.mp4';
import coverVideoWebM from '../../../static/videos/cover.webm';
import coverMobile from '../../../static/videos/mobile-video.mp4';

const WeCreate = styled.h1`
    font-size: 48px;
    font-family: ${TITLE_FONT};
    font-weight: bold;
    letter-spacing: -0.4px;
    margin-bottom: 20px;
    color: ${PRIMARY_TEXT_COLOR};

    @media (max-height: 700px) {
        letter-spacing: -0.2px;
        font-size: 32px;
    }

    margin-top: 100px;

    @media (max-width: 768px) {
        letter-spacing: -0.2px;
        font-size: 28px;
        margin-bottom: 40px;
    }

    text-shadow: 5px 5px 20px ${PRIMARY_TITLE_COLOR};
`;

// const ScrollDownContainer = styled.div`
//     margin-top: 30px;
//     border: 3px solid rgba(255, 255, 255, 0.7);
//     border-radius: 50px;
//     height: 30px;
//     width: 20px;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     left: 50%;
//     opacity: 0.6;
//     transform: translateX(-50%);
//     animation: fade 4s infinite cubic-bezier(0.75, -0.5, 0, 1.75);
//
//     @keyframes fade {
//         0%,
//         100% {
//             opacity: 1;
//             transform: scale(1);
//         }
//         85% {
//             opacity: 0.3;
//             transform: scale(0.9);
//         }
//     }
//     .wheel {
//         width: 3px;
//         height: 3px;
//         background: #fff;
//         display: block;
//         border-radius: 100%;
//         margin-top: 5px;
//         animation: boop 4s infinite cubic-bezier(0.75, -0.5, 0, 1.75);
//     }
//     @keyframes boop {
//         0%,
//         100% {
//             transform: translateY(0) scale(1);
//         }
//         85% {
//             transform: translateY(10px) scale(0.5);
//             opacity: 0.3;
//         }
//     }
// `;
//
// const ScrollDown = () => {
//     return (
//         <ScrollDownContainer>
//             <div className={'wheel'} />
//         </ScrollDownContainer>
//     );
// };

export const FrontPage = () => {
    return (
        <Fullscreen>
            <Heading
                cover={cover}
                video={[{ meta: 'video/webm', source: coverVideoWebM }, { meta: 'video/mp4', source: coverVideoMp4 }]}
                color={'white'}
                mobileVideo={{ meta: 'video/mp4', source: coverMobile }}
            >
                <Row>
                    <Col>
                        <WeCreate>
                            We create web
                            <br /> and mobile apps.
                        </WeCreate>
                    </Col>
                    <Col xs={12}>
                        <Link to={'/contact'}>
                            <CallToActionButton mb={'0'}>Let's start</CallToActionButton>
                        </Link>
                    </Col>
                </Row>
            </Heading>
        </Fullscreen>
    );
};
