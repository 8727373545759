import React from 'react';
import { CLUTCH_STATS, PRIMARY_TITLE_COLOR, TITLE_FONT } from '../../constants';
import styled from 'styled-components';
import clutch from '../../../static/images/awards/clutch.png';
import star from '../../../static/images/icons/stars.svg';

const ClutchReviewsContainer = styled.div`
    display: flex;
    flex-direction: row;
    img {
        width: 150px;

        @media (max-width: 768px) {
            width: 150px;
        }
    }
`;

const NumberOfStars = styled.div`
    font-size: 48px;
    font-family: ${TITLE_FONT};
    color: ${PRIMARY_TITLE_COLOR};
    letter-spacing: -0.4px;
    line-height: 55px;
`;

const StarsContainer = styled.div`
    background: url(${star}) repeat-x;
    font-size: 0;
    height: 23px;
    line-height: 0;
    overflow: hidden;
    text-indent: -999em;
    width: 120px;
`;

const Stars = styled.span`
    width: ${props => props.width}%;
    background: url(${star}) repeat-x;
    background-position: 0 100%;
    float: left;
    height: 23px;
    display: block;
`;

const NumberOfReviews = styled.div`
    font-size: 24px;
    font-weight: lighter;
    letter-spacing: -0.3px;
    color: ${PRIMARY_TITLE_COLOR};
    font-family: ${TITLE_FONT};
    white-space: nowrap;
    margin-top: 5px;
`;

export const ClutchReview = () => {
    return (
        <ClutchReviewsContainer>
            <div className="mr-5">
                <img src={clutch} alt={''} />
            </div>
            <div className="d-flex justify-content-center flex-column">
                <NumberOfStars>{CLUTCH_STATS.stars.toFixed(1)}</NumberOfStars>
                <StarsContainer>
                    <Stars width={(100 * CLUTCH_STATS.stars) / 5} />
                </StarsContainer>
                <NumberOfReviews>{CLUTCH_STATS.reviews} Reviews</NumberOfReviews>
            </div>
        </ClutchReviewsContainer>
    );
};
